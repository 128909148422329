import 'bootstrap-css-only/css/bootstrap.min.css';

import history from 'utils/history';

import PropTypes from 'prop-types';

import React from 'react';
import { isMobile as rddMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createStructuredSelector } from 'reselect';

import '@fortawesome/fontawesome-free/js/all';
import Redirect from 'components/Redirect';
import Admin from 'containers/Admin';
import Clinician from 'containers/Clinician';
import NotFoundPage from 'containers/NotFoundPage';
import Patient from 'containers/Patient';
import SignIn from 'containers/SignIn';
import SignUp from 'containers/SignUp';
import UserForgotPassword from 'containers/UserForgotPassword';
import 'global-styles.css';
import { getLogger } from 'utils/logger';
import { toastOptions } from 'utils/toast';

import {
	clearDownloadError,
	generateToast,
	pingServer,
	setMobile,
} from './actions';
import './index.css';
import {
	makeSelectAuth,
	makeSelectDownloadError,
	makeSelectIsMobile,
	makeSelectServerError,
	makeSelectToastMessage,
} from './selectors';

const logger = getLogger('App');
logger.log('App started');

/* eslint-disable react/prefer-stateless-function */
export class App extends React.Component {
	state = {
		serverError: false,
		handledDownloadError: false,
	};

	componentDidMount = () => {
		const { doPing } = this.props;

		window.addEventListener('resize', this.handleResize);
		this.handleResize();

		doPing();
	};

	handleResize = () => {
		const { setMobile, isMobile } = this.props;
		const newIsMobile = window.innerWidth < 1025 || rddMobile;

		if (isMobile !== newIsMobile) {
			setMobile(newIsMobile);
		}
	};

	getRoleTypeFromLocation = () => {
		if (this.props.auth && this.props.auth.isLoggedIn) {
			return `${this.props.auth.loggedInUser.roleName}`.toLowerCase();
		}
		return null;
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { toastMessage } = nextProps;
		const { serverError } = this.state;

		if (this.props.toastMessage !== toastMessage && toastMessage != null) {
			if (!toastMessage.type) {
				toast(toastMessage.text, {
					position: toastMessage.position || toast.POSITION.TOP_CENTER,
					autoClose: 2500,
					...toastMessage.options,
				});
			} else if (toastMessage.type === 'custom-success') {
				toast(toastMessage.text, {
					position: toast.POSITION.BOTTOM_LEFT,
					...toastOptions,
					...toastMessage.options,
				});
			} else if (toastMessage.type === 'custom-error') {
				toast(toastMessage.text, {
					position: toast.POSITION.BOTTOM_LEFT,
					...toastOptions,
					bodyClassName: 'attendance-toast-body-error',
					...toastMessage.options,
				});
			} else {
				toast[toastMessage.type](toastMessage.text, {
					position: toastMessage.position || toast.POSITION.TOP_CENTER,
					autoClose: 2500,
					...toastMessage.options,
				});
			}
		}

		if (serverError && !nextProps.serverError) {
			this.setState({ serverError: false });
		}

		if (nextProps.serverError && !serverError) {
			const { createToast, doPing } = this.props;
			createToast({
				type: 'error',
				text: 'Server Unavailable',
				options: { bodyClassName: 'attendance-toast-body-error' },
			});

			const pingCheck = setInterval(() => {
				doPing();

				if (!this.state.serverError) {
					clearInterval(pingCheck);
					createToast({ type: 'success', text: 'Reconnected!' });
				}
			}, 5000);

			this.setState({ serverError: true });
		}

		if (nextProps.downloadError && !this.props.downloadError) {
			const { createToast } = this.props;

			createToast({
				type: 'error',
				text: 'Could not download file',
				options: { bodyClassName: 'attendance-toast-body-error' },
			});

			clearDownloadError();
		}
	}

	getComponentFromRoleName(name) {
		let component;

		if (name === 'patient') {
			component = Patient;
		} else if (name === 'clinician') {
			component = Clinician;
		} else if (name === 'admin') {
			component = Admin;
		}

		return component;
	}

	render() {
		const roleName = this.getRoleTypeFromLocation();
		const { serverError } = this.state;

		return (
			<Router history={history}>
				<div style={{ paddingBottom: '2rem' }}>
					<ToastContainer hideProgressBar autoClose={5000} />
					<div
						className="form-error"
						style={{
							position: 'absolute',
							top: '5px',
							left: '10px',
							zIndex: 1021,
						}}
					>
						{serverError && 'Server unavailable'}
					</div>
					{roleName && (
						<Switch>
							<Route
								path="/"
								component={this.getComponentFromRoleName(roleName)}
							/>
							<Route component={NotFoundPage} />
						</Switch>
					)}
					{!roleName && (
						<Switch>
							<Route exact path="/admin/signin" component={SignIn} />
							<Route exact path="/admin/signup" component={SignUp} />
							<Route
								path="/admin"
								component={() => <Redirect redirectPath="/admin/signin" />}
							/>
							<Route exact path="/clinician/signin" component={SignIn} />
							<Route exact path="/clinician/signup" component={SignUp} />
							<Route
								path="/clinician"
								component={() => <Redirect redirectPath="/clinician/signin" />}
							/>
							<Route exact path="/patient/signin" component={SignIn} />
							<Route exact path="/patient/signup" component={SignUp} />
							<Route
								path="/patient"
								component={() => <Redirect redirectPath="/patient/signin" />}
							/>
							<Route
								exact
								path="/"
								component={() => <Redirect redirectPath="/signin" />}
							/>
							<Route exact path="/signin" component={SignIn}></Route>
							<Route
								path="/user-forgot-password"
								component={UserForgotPassword}
							/>
							<Route component={NotFoundPage} />
						</Switch>
					)}
				</div>
			</Router>
		);
	}
}

App.propTypes = {
	auth: PropTypes.object.isRequired,
	toastMessage: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
	auth: makeSelectAuth(),
	toastMessage: makeSelectToastMessage(),
	serverError: makeSelectServerError(),
	isMobile: makeSelectIsMobile(),
	downloadError: makeSelectDownloadError(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		doPing: () => dispatch(pingServer()),
		createToast: (msg) => dispatch(generateToast(msg)),
		setMobile: (value) => dispatch(setMobile(value)),
		clearDownloadError: () => dispatch(clearDownloadError()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
