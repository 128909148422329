import { uniqBy } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Col, Row, TabContent, TabPane } from 'reactstrap';

import { createStructuredSelector } from 'reselect';

import NavTab from 'atoms/NavTab';
import PdfViewer from 'atoms/PdfViewer';
import SearchBar from 'atoms/SearchBar';
import KebabMenuCell from 'atoms/TableCell/KebabMenuCell';
import FilterNav from 'components/FilterNav';
import NewIntakes from 'components/NewIntakes';
import OtherRequests from 'components/OtherRequests';
import ValidateSentModal from 'components/ValidateSentModal';
import Absences from 'containers/Absences';
import {
	deleteRequest,
	downloadFile,
	fetchEnrollRequests,
	fetchMemberRequests,
	loadClinicians,
	updateRequest,
	viewPdf,
} from 'containers/App/actions';
import {
	makeSelectAuth,
	makeSelectClinicians,
	makeSelectEnrollReq,
	makeSelectFile,
	makeSelectIsFetchingEnrollReq,
	makeSelectIsFetchingMemberReq,
	makeSelectIsLoadingFile,
	makeSelectIsMobile,
	makeSelectMemberReq,
	makeSelectStates,
} from 'containers/App/selectors';
import EnrollmentRequests from 'containers/EnrollmentRequests';
import Feedback from 'containers/Feedback';
import { fetchFeedback } from 'containers/Feedback/actions';
import { makeSelectFeedback } from 'containers/Feedback/selectors';
import { MemberRequests } from 'containers/MemberRequests';
import { updateNotifications } from 'containers/Notifications/actions';
import { makeSelectAlerts } from 'containers/Notifications/selectors';
import RxRequests from 'containers/RxRequests';
import { compareDates, compareWords } from 'utils/columnCompare';
import { filterByLetter, filterData, getFilterOptions } from 'utils/helpers';
import { getLogger } from 'utils/logger';

import {
	assignClinician,
	loadNewIntakes,
	loadOtherRequests,
	loadRxRequests,
} from './actions';
import { tabLabels } from './constants';
import todoFilters from './filters';
import './index.css';
import {
	makeSelectIsFetchingNewIntakes,
	makeSelectIsFetchingOtherReq,
	makeSelectIsFetchingRXReq,
	makeSelectNewIntakes,
	makeSelectOtherRequests,
	makeSelectRxRequests,
	makeSelectToDos,
} from './selectors';

const commonLabels = (notifications) => [
	{
		name: 'Rx Requests',
		id: tabLabels.RX_REQUESTS,
	},
	{
		name: 'Absences',
		id: tabLabels.ABSENCES,
		notify: notifications.findIndex((n) => !n.hasSeen) > -1,
	},
];
const clinicianLabels = (notifications) => [
	{
		name: 'Enrollment Requests',
		id: tabLabels.ENROLLMENT_REQUESTS,
	},
	{
		name: 'Member Requests',
		id: tabLabels.MEMBER_REQUESTS,
	},
	...commonLabels(notifications),
];
const adminLabels = (notifications) => [
	{
		name: 'New Intakes',
		id: tabLabels.NEW_INTAKES,
	},
	{
		name: 'Other Requests',
		id: tabLabels.OTHER_REQUESTS,
	},
	...commonLabels(notifications),
	{
		name: 'Client Feedback',
		id: tabLabels.CLIENT_FEEDBACK,
	},
];

const logger = getLogger('containers.ToDos');

/* eslint-disable react/prefer-stateless-function */
export class ToDos extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: '',
			filterClicked: true,
			newIntakes: [],
			rxRequests: [],
			enrollReq: [],
			memberReq: [],
			otherRequests: [],
			absences: [],
			filters: new Map(),
			tabDropdownOpen: false,
			userSearch: '',
			showInactiveEnroll: false,
			showInactiveMember: false,
			showInactiveOther: false,
			showDeleteRequestModal: false,
			showPdfViewer: false,
			activeFile: null,
			from: null,
			to: null,
		};

		this.scrollRef = createRef();
	}

	currentFilters = () => {
		const { activeTab, filters } = this.state;
		return filters.get(activeTab) || [];
	};

	componentDidMount() {
		const {
			type,
			dispatch,
			routeProps: { location },
		} = this.props;
		dispatch(loadClinicians());

		if (type === 'clinician') {
			if (location?.state) {
				const { type } = location.state;
				switch (type) {
					case 'Enrollment Request':
					default:
						this.toggle(tabLabels.ENROLLMENT_REQUESTS);
						break;
					case 'Rx':
						this.toggle(tabLabels.RX_REQUESTS);
						break;
					case 'LEAVE':
					case 'ROI':
					case 'DISCONTINUANCE':
						this.toggle(tabLabels.MEMBER_REQUESTS);
						break;
				}
			} else {
				this.toggle(tabLabels.ENROLLMENT_REQUESTS);
			}
		}
		if (type === 'admin') {
			if (location?.state) {
				const { activeTab } = location.state;
				switch (activeTab) {
					case 'other':
						this.toggle(tabLabels.OTHER_REQUESTS);
						break;
					case 'rx':
						this.toggle(tabLabels.RX_REQUESTS);
						break;
					default:
						this.toggle(tabLabels.NEW_INTAKES);
						break;
				}
			} else {
				this.toggle(tabLabels.NEW_INTAKES);
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.newIntakes ||
			nextProps.rxRequests ||
			nextProps.memberReq ||
			nextProps.enrollReq ||
			nextProps.otherRequests ||
			nextProps.feedback ||
			nextProps.notifications
		) {
			this.setState({
				newIntakes: nextProps.newIntakes,
				rxRequests: nextProps.rxRequests,
				enrollReq: nextProps.enrollReq,
				memberReq: nextProps.memberReq,
				otherRequests: nextProps.otherRequests,
				feedback: nextProps.feedback,
				absences: nextProps.notifications,
			});
		}

		if (this.props.type !== nextProps.type) {
			const activeTab = (
				nextProps.type === 'admin' ? adminLabels[0].id : clinicianLabels[0].id
			).toString();
			this.setState(() => ({ activeTab }));
		}
	}

	downloadFile = (docId) => {
		if (!docId) return;

		const { dispatch } = this.props;
		dispatch(downloadFile(docId));
	};

	assignClinician = (data) => {
		const { dispatch } = this.props;

		dispatch(assignClinician(data));
	};

	handleChange = () => {
		this.setState((prevState) => ({ filterClicked: !prevState.filterClicked }));
	};

	toggle = (tab) => {
		const { dispatch } = this.props;
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
			switch (tab) {
				case tabLabels.ENROLLMENT_REQUESTS:
					dispatch(fetchEnrollRequests());
					break;
				case tabLabels.RX_REQUESTS:
					dispatch(loadRxRequests());
					break;
				case tabLabels.MEMBER_REQUESTS:
					dispatch(fetchMemberRequests());
					break;
				case tabLabels.NEW_INTAKES:
					dispatch(loadNewIntakes());
					break;
				case tabLabels.OTHER_REQUESTS:
					dispatch(loadOtherRequests());
					break;
				case tabLabels.CLIENT_FEEDBACK:
					dispatch(fetchFeedback());
					break;
				default:
					break;
			}
		}
	};

	togglePdfViewer = () => {
		this.setState((prevState) => ({
			showPdfViewer: !prevState.showPdfViewer,
			activeFile: null,
		}));
	};

	viewPdf = (data) => {
		const { viewPdf } = this.props;
		viewPdf(data.uuid, () => logger.log('Loaded'));
		this.setState({
			showPdfViewer: true,
			activeFile: data,
		});
	};

	updateRequest = (id, state, payload) => {
		const { auth } = this.props;
		let data = {
			id,
			senderUserId: auth.loggedInUser.id,
		};

		if (typeof state === 'string') {
			data.state = state;
			data.payload = payload;
		} else {
			data.isActive = state;
		}

		const { dispatch } = this.props;
		dispatch(updateRequest(data));
		// dispatch(fetchEnrollRequests());
	};

	handleDeleteRequest = () => {
		const { auth } = this.props;
		const { activeRequest } = this.state;
		const data = {
			id: activeRequest,
			senderUserId: auth.loggedInUser.id,
		};

		const { dispatch } = this.props;
		dispatch(deleteRequest(data));
		this.setState(() => ({
			showDeleteRequestModal: false,
			activeRequest: null,
		}));
	};

	deleteRequest = (id) => {
		this.setState(() => ({
			showDeleteRequestModal: true,
			activeRequest: id,
		}));
	};

	getDropdownLabel = (activeTab, type) => {
		const { notifications } = this.props;
		let label;
		if (type === 'admin') {
			label = adminLabels(notifications).find(
				(label) => label.id.toString() === activeTab,
			);
		} else if (type === 'clinician') {
			label = clinicianLabels(notifications).find(
				(label) => label.id.toString() === activeTab,
			);
		}

		return label ? label.name : 'No Options';
	};

	getTabContent = (activeTab, type) => {
		const {
			auth,
			clinicians,
			states,
			isFetchingNewIntakes,
			isFetchingRXReq,
			isFetchingMemberReq,
			isFetchingOtherReq,
			isFetchingEnrollReq,
			isMobile,
			readNotification,
		} = this.props;

		let {
			newIntakes,
			rxRequests,
			enrollReq,
			feedback,
			absences,
			otherRequests,
			memberReq,
			userSearch,
			showInactiveEnroll,
			showInactiveMember,
			showInactiveOther,
			showInactiveRx,
		} = this.state;

		if (!Array.isArray(enrollReq)) {
			enrollReq = [];
		}
		if (!Array.isArray(memberReq)) {
			memberReq = [];
		}
		if (!Array.isArray(rxRequests)) {
			rxRequests = [];
		}
		if (!Array.isArray(otherRequests)) {
			otherRequests = [];
		}
		if (!Array.isArray(absences)) {
			absences = [];
		}

		const filters = this.currentFilters();

		let tabcontent;

		if (type === 'clinician') {
			if (activeTab === tabLabels.ENROLLMENT_REQUESTS) {
				if (!showInactiveEnroll) {
					enrollReq = enrollReq.filter((r) => r.isActive);
				}

				filters.forEach((filter) => {
					enrollReq = filter.callback(filter.name, filter.option, enrollReq);
				});
			} else if (activeTab === tabLabels.MEMBER_REQUESTS) {
				if (!showInactiveMember) {
					memberReq = memberReq.filter((r) => r.isActive);
				}

				filters.forEach((filter) => {
					memberReq = filter.callback(filter.name, filter.option, memberReq);
				});
			} else if (activeTab === tabLabels.RX_REQUESTS) {
				if (!showInactiveRx) {
					rxRequests = rxRequests.filter((r) => r.isActive);
				}

				filters.forEach((filter) => {
					rxRequests = filter.callback(filter.name, filter.option, rxRequests);
				});
			} else if (activeTab === tabLabels.ABSENCES) {
				filters.forEach((filter) => {
					absences = filter.callback(filter.name, filter.option, absences);
				});
			}

			tabcontent = (
				<TabContent activeTab={activeTab}>
					{activeTab === tabLabels.ENROLLMENT_REQUESTS && (
						<TabPane tabId={tabLabels.ENROLLMENT_REQUESTS}>
							<div className="row">
								<div className="col-sm-12">
									<EnrollmentRequests
										data={enrollReq || []}
										role={auth.loggedInUser.roleName}
										downloadFile={this.downloadFile}
										updateRequest={this.updateRequest}
										deleteRequest={this.deleteRequest}
										auth={auth}
										loading={isFetchingEnrollReq}
										userSearch={userSearch}
										viewPdf={this.viewPdf}
									/>
								</div>
							</div>
						</TabPane>
					)}
					{activeTab == tabLabels.MEMBER_REQUESTS && (
						<TabPane tabId={tabLabels.MEMBER_REQUESTS}>
							<div className="row">
								<div className="col-sm-12">
									<MemberRequests
										data={memberReq || []}
										role={auth.loggedInUser.roleName}
										auth={auth}
										downloadFile={this.downloadFile}
										updateRequest={this.updateRequest}
										deleteRequest={this.deleteRequest}
										loading={isFetchingMemberReq}
										isMobile={isMobile}
										userSearch={userSearch}
										viewPdf={this.viewPdf}
									/>
								</div>
							</div>
						</TabPane>
					)}
					{activeTab === tabLabels.RX_REQUESTS && (
						<TabPane tabId={tabLabels.RX_REQUESTS}>
							<div className="row">
								<div className="col-sm-12">
									<RxRequests
										data={rxRequests || []}
										updateRequest={this.updateRequest}
										deleteRequest={this.deleteRequest}
										role={auth.loggedInUser.roleName}
										loading={isFetchingRXReq}
										isMobile={isMobile}
										userSearch={userSearch}
									/>
								</div>
							</div>
						</TabPane>
					)}
					{activeTab === tabLabels.ABSENCES && (
						<TabPane tabId={tabLabels.ABSENCES}>
							<div className="row">
								<div className="col-sm-12">
									<Absences
										role={type}
										data={absences.sort((a, b) =>
											compareDates(
												a.attendance.date,
												b.attendance.date,
												'desc',
											),
										)}
									/>
								</div>
							</div>
						</TabPane>
					)}
				</TabContent>
			);
		}
		if (type === 'admin') {
			if (activeTab === tabLabels.NEW_INTAKES) {
				filters.forEach((filter) => {
					newIntakes = filter.callback(filter.name, filter.option, newIntakes);
				});
			} else if (activeTab === tabLabels.RX_REQUESTS) {
				if (!showInactiveRx) {
					rxRequests = rxRequests.filter((r) => r.isActive);
				}
				filters.forEach((filter) => {
					rxRequests = filter.callback(filter.name, filter.option, rxRequests);
				});
			} else if (activeTab === tabLabels.OTHER_REQUESTS) {
				if (!showInactiveOther) {
					otherRequests = otherRequests.filter((r) => r.isActive);
				}
				filters.forEach((filter) => {
					otherRequests = filter.callback(
						filter.name,
						filter.option,
						otherRequests,
					);
				});
			} else if (activeTab === tabLabels.CLIENT_FEEDBACK) {
				filters.forEach((filter) => {
					feedback = filter.callback(filter.name, filter.option, feedback);
				});
			} else if (activeTab === tabLabels.ABSENCES) {
				filters.forEach((filter) => {
					absences = filter.callback(filter.name, filter.option, absences);
				});
			}

			tabcontent = (
				<TabContent activeTab={activeTab}>
					{activeTab === tabLabels.NEW_INTAKES && (
						<TabPane tabId={tabLabels.NEW_INTAKES}>
							<Row>
								<Col sm="12">
									<NewIntakes
										role={type}
										data={newIntakes}
										downloadFile={this.downloadFile}
										clinicians={clinicians}
										assignClinician={this.assignClinician}
										loading={isFetchingNewIntakes}
										isMobile={isMobile}
										userSearch={userSearch}
										backToTop={this.backToTop}
									/>
								</Col>
							</Row>
						</TabPane>
					)}
					{activeTab === tabLabels.RX_REQUESTS && (
						<TabPane tabId={tabLabels.RX_REQUESTS}>
							<div className="row">
								<div className="col-sm-12">
									<RxRequests
										data={rxRequests}
										clinicians={clinicians}
										updateRequest={this.updateRequest}
										deleteRequest={this.deleteRequest}
										assignClinician={this.assignClinician}
										states={states}
										loading={isFetchingRXReq}
										isMobile={isMobile}
										userSearch={userSearch}
									/>
								</div>
							</div>
						</TabPane>
					)}
					{activeTab === tabLabels.OTHER_REQUESTS && (
						<TabPane tabId={tabLabels.OTHER_REQUESTS}>
							<Row>
								<Col sm="12">
									<OtherRequests
										data={otherRequests}
										downloadFile={this.downloadFile}
										deleteRequest={this.deleteRequest}
										updateRequest={this.updateRequest}
										loading={isFetchingOtherReq}
										userSearch={userSearch}
										isMobile={isMobile}
									/>
								</Col>
							</Row>
						</TabPane>
					)}
					{activeTab === tabLabels.CLIENT_FEEDBACK && (
						<TabPane tabId={tabLabels.CLIENT_FEEDBACK}>
							<Row>
								<Col sm="12">
									<Feedback feedback={feedback} />
								</Col>
							</Row>
						</TabPane>
					)}
					{activeTab === tabLabels.ABSENCES && (
						<TabPane tabId={tabLabels.ABSENCES}>
							<div className="row">
								<div className="col-sm-12">
									<Absences
										role={type}
										data={absences.sort((a, b) =>
											compareDates(
												a.attendance.date,
												b.attendance.date,
												'desc',
											),
										)}
										markRead={readNotification}
									/>
								</div>
							</div>
						</TabPane>
					)}
				</TabContent>
			);
		}
		return tabcontent;
	};

	backToTop = () => {
		this.scrollRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
	};

	generateFilters = () => {
		let {
			newIntakes,
			rxRequests,
			enrollReq,
			otherRequests,
			memberReq,
			feedback,
			notifications,
		} = this.props;

		const { activeTab } = this.state;
		const options = {};

		if (!Array.isArray(newIntakes)) {
			newIntakes = [];
		}
		if (!Array.isArray(rxRequests)) {
			rxRequests = [];
		}
		if (!Array.isArray(enrollReq)) {
			enrollReq = [];
		}
		if (!Array.isArray(otherRequests)) {
			otherRequests = [];
		}
		if (!Array.isArray(memberReq)) {
			memberReq = [];
		}

		switch (activeTab) {
			case tabLabels.NEW_INTAKES:
				options.name = getFilterOptions(newIntakes, 'name');
				options.clinicianName = getFilterOptions(newIntakes, 'clinicianName');
				if (newIntakes.length > 0) {
					options.minDate = newIntakes[newIntakes.length - 1].createdAt;
					options.maxDate = newIntakes[0].createdAt;
				}
				break;
			case tabLabels.ENROLLMENT_REQUESTS:
				options.requestorName = getFilterOptions(enrollReq, 'requestorName');
				options.groupName = getFilterOptions(enrollReq, 'groupName');
				if (enrollReq.length > 0) {
					options.minDate = enrollReq[enrollReq.length - 1].createdAt;
					options.maxDate = enrollReq[0].createdAt;
				}
				break;
			case tabLabels.RX_REQUESTS:
				options.requestorName = getFilterOptions(rxRequests, 'requestorName');
				options.pharmacyName = getFilterOptions(rxRequests, 'pharmacyName');
				options.requestorName = getFilterOptions(rxRequests, 'requestorName');
				options.clinicianName = getFilterOptions(
					rxRequests,
					'payload.clinician.name',
				);
				if (rxRequests.length > 0) {
					options.minDate = rxRequests[rxRequests.length - 1].createdAt;
					options.maxDate = rxRequests[0].createdAt;
				}
				break;
			case tabLabels.OTHER_REQUESTS:
				options.name = getFilterOptions(otherRequests, 'name');
				options.roleName = getFilterOptions(otherRequests, 'roleName');
				if (otherRequests.length > 0) {
					options.minDate = otherRequests[otherRequests.length - 1].createdAt;
					options.maxDate = otherRequests[0].createdAt;
				}
				break;
			case tabLabels.MEMBER_REQUESTS:
				options.requestType = getFilterOptions(memberReq, 'requestType');
				options.requestorState = getFilterOptions(memberReq, 'requestorState');
				options.requestorName = getFilterOptions(memberReq, 'requestorName');
				if (memberReq.length > 0) {
					options.minDate = memberReq[memberReq.length - 1].createdAt;
					options.maxDate = memberReq[0].createdAt;
				}
				break;
			case tabLabels.CLIENT_FEEDBACK:
				options.type = getFilterOptions(feedback, 'type');
				options.completed = ['Completed', 'Requires Action'];
				break;
			case tabLabels.ABSENCES:
				options.groupName = getFilterOptions(notifications, 'program.name');
				if (notifications.length > 0) {
					options.minDate =
						notifications[notifications.length - 1].attendance.date;
					options.maxDate = notifications[0].attendance.date;
				}
				break;
			default:
				break;
		}

		return todoFilters(options, activeTab);
	};

	filterByDate = (name, option, data) => {
		if (!option) return data;

		const filtersToTabs = [
			'dateRange-enroll',
			'dateRange-member',
			'dateRange-new_intakes',
			'dateRange-rx',
			'dateRange-other',
			'dateRange-feedback',
			'dateRange-absences',
		];
		const { activeTab } = this.state;
		if (!name.includes(filtersToTabs[activeTab - 1])) return data;

		const date = moment(option);

		if (activeTab === tabLabels.ABSENCES) {
			return data.filter((r) =>
				name.includes('from')
					? moment(r.attendance.date).isSameOrAfter(date.startOf('day'))
					: moment(r.attendance.date).isSameOrBefore(date.endOf('day')),
			);
		} else if (activeTab === tabLabels.NEW_INTAKES) {
			return data.filter((r) =>
				name.includes('from')
					? moment(r.updatedAt).isSameOrAfter(date.startOf('day'))
					: moment(r.updatedAt).isSameOrBefore(date.endOf('day')),
			);
		} else {
			return data.filter((r) =>
				name.includes('from')
					? moment(r.createdAt).isSameOrAfter(date.startOf('day'))
					: moment(r.createdAt).isSameOrBefore(date.endOf('day')),
			);
		}
	};

	filterByLetter = (name, value) => {
		const { newIntakes, rxRequests, enrollReq, otherRequests, memberReq } =
			this.props;

		const { activeTab } = this.state;
		if (activeTab === tabLabels.NEW_INTAKES) {
			const filteredData = filterByLetter(newIntakes, name, value);
			this.setState({
				newIntakes: filteredData,
			});
		} else if (activeTab === tabLabels.ENROLLMENT_REQUESTS) {
			const filteredData = filterByLetter(enrollReq, name, value);
			this.setState({
				enrollReq: filteredData,
			});
		} else if (activeTab === tabLabels.RX_REQUESTS) {
			const filteredData = filterByLetter(rxRequests, name, value);
			this.setState({
				rxRequests: filteredData,
			});
		} else if (activeTab === tabLabels.OTHER_REQUESTS) {
			const filteredData = filterByLetter(otherRequests, name, value);
			this.setState({
				otherRequests: filteredData,
			});
		} else if (activeTab === tabLabels.MEMBER_REQUESTS) {
			const filteredData = filterByLetter(memberReq, name, value);
			this.setState({
				memberReq: filteredData,
			});
		}
	};

	filterDropdown = (name, value, data) => {
		const { activeTab } = this.state;
		if (activeTab === tabLabels.NEW_INTAKES) {
			return filterData(data, name, value);
		} else if (activeTab === tabLabels.RX_REQUESTS) {
			let path = '';
			if (name === 'clinicianName') {
				path = 'payload.clinician.name';
			} else {
				path = name;
			}

			return filterData(data, path, value);
		} else if (activeTab === tabLabels.CLIENT_FEEDBACK && name === 'status') {
			return data.filter((f) => {
				if (value === 'Completed') return f.completed;
				else if (value === 'Requires Action') return !f.completed;
				else return f;
			});
		} else {
			return filterData(data, name, value);
		}
	};

	addFilter = (filter) => {
		let { filters, activeTab } = this.state;
		let newFilters = this.currentFilters();

		newFilters = newFilters.filter((x) => x.name !== filter.name);
		newFilters.push(filter);

		filters.set(activeTab, newFilters);
		this.setState({ filters });
	};

	removeFilter = (name) => {
		let { filters, activeTab } = this.state;
		let newFilters = this.currentFilters();

		newFilters = newFilters.filter((x) => x.name !== name);
		filters.set(activeTab, newFilters);

		this.setState({ filters });
	};

	searchForClient = () => {
		const {
			newIntakes,
			enrollReq,
			rxRequests,
			otherRequests,
			memberReq,
			notifications,
		} = this.props;
		const { userSearch, activeTab } = this.state;

		if (activeTab === tabLabels.CLIENT_FEEDBACK) return null;

		let dropdownList = [];

		if (activeTab === tabLabels.ENROLLMENT_REQUESTS) {
			dropdownList = enrollReq?.map((x) => ({
				label: x.requestorName,
				value: x.requestorName,
			}));
		} else if (activeTab === tabLabels.MEMBER_REQUESTS) {
			dropdownList = memberReq?.map((x) => ({
				label: x.requestorName,
				value: x.requestorName,
			}));
		} else if (activeTab === tabLabels.NEW_INTAKES) {
			dropdownList = newIntakes?.map((x) => ({ label: x.name, value: x.name }));
		} else if (activeTab === tabLabels.RX_REQUESTS) {
			dropdownList = rxRequests?.map((x) => ({
				label: x.requestorName,
				value: x.requestorName,
			}));
		} else if (activeTab === tabLabels.OTHER_REQUESTS) {
			dropdownList = otherRequests?.map((x) => ({
				label: x.name,
				value: x.name,
			}));
		} else if (activeTab === tabLabels.ABSENCES) {
			dropdownList = notifications?.map((x) => ({
				label: x.patient.name,
				value: x.patient.name,
			}));
		}

		if (!Array.isArray(dropdownList)) {
			dropdownList = [];
		}

		dropdownList = uniqBy(dropdownList, (e) => e.label);
		dropdownList = dropdownList.sort((a, b) =>
			compareWords(a.label, b.label, 'asc'),
		);

		return (
			<SearchBar
				value={userSearch}
				style={{
					searchBox: { height: 52 },
				}}
				onSearch={(value) => {
					this.setState({
						userSearch: value,
					});
				}}
				dropdownList={dropdownList}
			/>
		);
	};

	filterKebab = () => {
		const { activeTab, showCompleted } = this.state;

		if (activeTab !== tabLabels.CLIENT_FEEDBACK) return null;

		return (
			<KebabMenuCell
				dropdownItems={[
					{
						id: 1,
						name: `${showCompleted ? 'Hide' : 'Show'} Completed`,
						callback: this.toggleCompleted,
						enabled: true,
					},
				]}
				noMargin
			/>
		);
	};

	handleCheck = (name) => {
		this.setState((prevState) => ({
			[name]: !prevState[name],
		}));
	};

	handleAcknowledgeAbsence = (id) => {
		const { readNotification } = this.props;
		readNotification([id]);
	};

	render() {
		const { type, isMobile, dispatch, file, isLoadingFile, notifications } =
			this.props;
		const { activeTab, showPdfViewer, activeFile, showDeleteRequestModal } =
			this.state;

		return (
			<div
				className={`container-fluid ${isMobile ? 'mt-3 px-2' : 'pt-5 px-5'}`}
			>
				<div ref={this.scrollRef} />
				<div className="">
					<NavTab
						type={type}
						activeTab={activeTab}
						handleChange={this.handleChange}
						isMobile={isMobile}
						getLabel={this.getDropdownLabel}
						labels={{
							admin: adminLabels(notifications),
							clinician: clinicianLabels(notifications),
						}}
						switchTab={this.toggle}
					/>
					<FilterNav
						filtersList={this.generateFilters()}
						filterDropdown={this.filterDropdown}
						filterByLetter={this.filterByLetter}
						filterByDate={this.filterByDate}
						handleCheck={this.handleCheck}
						addFilter={this.addFilter}
						removeFilter={this.removeFilter}
						currentFilters={this.currentFilters()}
						isMobile={isMobile}
						dispatch={dispatch}
						search={this.searchForClient()}
						kebab={this.filterKebab()}
					/>
					{this.getTabContent(activeTab, type)}
					{showPdfViewer && (
						<PdfViewer
							isOpen={showPdfViewer}
							toggle={this.togglePdfViewer}
							file={file}
							documentData={activeFile}
							isLoading={isLoadingFile}
							downloadFile={() => this.downloadFile(activeFile.uuid)}
						/>
					)}
					{showDeleteRequestModal && (
						<ValidateSentModal
							hide={() =>
								this.setState({
									showDeleteRequestModal: false,
									activeRequest: null,
								})
							}
							handleDelete={this.handleDeleteRequest}
							message="You are deleting this request. This action is PERMANENT and CANNOT be undone."
						/>
					)}
				</div>
			</div>
		);
	}
}

ToDos.propTypes = {
	type: PropTypes.string.isRequired,
	otherRequests: PropTypes.array.isRequired,
	newIntakes: PropTypes.array.isRequired,
	dispatch: PropTypes.func.isRequired,
	rxRequests: PropTypes.array,
	enrollReq: PropTypes.array,
	memberReq: PropTypes.array,
	auth: PropTypes.object,
	clinicians: PropTypes.array,
	states: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
	toDos: makeSelectToDos(),
	otherRequests: makeSelectOtherRequests(),
	newIntakes: makeSelectNewIntakes(),
	rxRequests: makeSelectRxRequests(),
	enrollReq: makeSelectEnrollReq(),
	memberReq: makeSelectMemberReq(),
	feedback: makeSelectFeedback(),
	auth: makeSelectAuth(),
	clinicians: makeSelectClinicians(),
	states: makeSelectStates(),
	isFetchingNewIntakes: makeSelectIsFetchingNewIntakes(),
	isFetchingRXReq: makeSelectIsFetchingRXReq(),
	isFetchingMemberReq: makeSelectIsFetchingMemberReq(),
	isFetchingOtherReq: makeSelectIsFetchingOtherReq(),
	isFetchingEnrollReq: makeSelectIsFetchingEnrollReq(),
	isMobile: makeSelectIsMobile(),
	isLoadingFile: makeSelectIsLoadingFile(),
	file: makeSelectFile(),
	notifications: makeSelectAlerts(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		viewPdf: (uuid, cb) => dispatch(viewPdf(uuid, cb)),
		readNotification: (data) => dispatch(updateNotifications(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ToDos);
