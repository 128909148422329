import history from 'utils/history';

import moment from 'moment';

import PropTypes from 'prop-types';

import React, { useCallback, useEffect, useState } from 'react';
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';

import styled from 'styled-components';

import { headerStyle } from 'containers/App/constants';
import { getAttendanceColor } from 'utils/helpers';

import AttendanceBlock from './AttendanceBlock';
import './style.css';

const Day = styled.div`
	border: 1px solid #aedef5;
	min-height: 180px;

	div:hover .group-data {
		color: white;
	}
`;

const LegendContainer = styled.div`
	position: absolute;
	top: 1rem;
	left: 0.5rem;
	font-size: 14px;
`;

const ColorBlock = styled.div`
	background: ${(props) => getAttendanceColor(props.type)};
	width: 20px;
	height: 20px;

	:after {
		content: '${(props) => props.type}';
		color: ${(props) => getAttendanceColor(props.type)};
		position: absolute;
		left: 24px;
	}
`;

const AttendanceRecord = (props) => {
	const { programs, attendance, sessions = [], type } = props;
	const [monthOptions, setMonths] = useState([]);
	const [selectedMonth, setMonth] = useState({});
	const [monthIndex, setMonthIndex] = useState(0);
	const [monthDropdownOpen, setMonthDropdownOpen] = useState(false);

	useEffect(() => {
		let firstMonth = moment('12-31-3030');
		let lastMonth = moment('01-01-1890');

		if (!Array.isArray(sessions)) return;

		sessions.forEach((s) => {
			const startDateAsMoment = moment
				.utc(s.sessionDetails[0].startDate)
				.local();
			const endDateAsMoment = moment
				.utc(s.sessionDetails[s.sessionDetails.length - 1].endDate)
				.local();

			if (startDateAsMoment.isBefore(firstMonth)) {
				firstMonth = startDateAsMoment;
			}
			if (endDateAsMoment.isAfter(lastMonth)) {
				lastMonth = endDateAsMoment;
			}
		});

		setMonthIndex(0);

		let newOptions = [];
		let index = 0;
		while (firstMonth <= lastMonth) {
			let month = firstMonth;
			newOptions.push({
				label: month.format('MMMM YYYY'),
				value: month.format('YYYY-MM'),
			});
			firstMonth.add(1, 'month');

			index++;
			if (moment().format('MM YYYY') === month.format('MM YYYY')) {
				setMonthIndex(index);
			}
		}

		setMonths(newOptions);
	}, [programs, sessions, attendance]);

	useEffect(() => {
		let newMonth = monthOptions[monthIndex];
		if (newMonth) {
			setMonth(newMonth);
		}
	}, [monthIndex, monthOptions]);

	const prevMonth = () => {
		if (monthIndex > 0) {
			setMonthIndex(monthIndex - 1);
		}
	};

	const nextMonth = () => {
		if (monthIndex < monthOptions.length - 1) {
			setMonthIndex(monthIndex + 1);
		}
	};

	const toggleMonthDropdown = () => {
		setMonthDropdownOpen(!monthDropdownOpen);
	};

	const goto = (route) => {
		history.push({
			pathname: `/${type}/dashboard/${route}`,
		});
	};

	const renderMonth = useCallback(() => {
		const noDays = moment(selectedMonth.value).daysInMonth();
		const noWeeks = Math.floor(noDays / 7) + 1;
		const monthStart = moment(selectedMonth.value).startOf('month').day();

		const dates = [];
		for (let i = 1; i <= noDays; i++) {
			dates.push({ date: i, sessionData: [] });
		}

		const timeFormat = 'h:mma';
		sessions.forEach((s) => {
			const program = programs.find((p) => p.program_id === s.programId);

			if (!program) return;

			const sessions = s.sessionDetails.filter((sd) => {
				return moment(selectedMonth.value).isSame(
					moment.utc(sd.startDate).local(), // check month after converting to local
					'month',
				);
			});

			sessions.forEach((session) => {
				// convert utc time to local
				const date = moment.utc(session.startDate).local().date();
				const attendanceData = attendance.find(
					(a) => a.sessionId === session.sessionId,
				);

				if (!dates[date - 1].sessionData) {
					return;
				}

				dates[date - 1].sessionData.push({
					program: {
						...program,
						startTime: moment.utc(session.startDate).local().format(timeFormat),
						endTime: moment.utc(sessions.endDate).local().format(timeFormat),
					},
					attendance: attendanceData,
				});
			});
		});

		const weeks = new Array(noWeeks).fill(new Array(7).fill({}));

		let useDateArray = false;
		let dateIndex = 0;
		const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

		return (
			<div>
				<div className="row">
					{days.map((d, i) => (
						<div
							key={d}
							className={`${
								i === 0 || i === 6 ? 'col-sm-1' : 'col'
							} p-0 text-center`}
							style={headerStyle}
						>
							{d}
						</div>
					))}
				</div>
				{weeks.map((w) => (
					<div className="row" key={w}>
						{w.map((_, j) => {
							let dateData = { sessionData: [] };

							if (!useDateArray && j === monthStart) {
								useDateArray = true;
							}

							if (useDateArray && dateIndex < dates.length) {
								dateData = dates[dateIndex];
								dateData.sessionData.sort((a, b) => {
									const aTime = moment(a.program.startTime, timeFormat);
									const bTime = moment(b.program.startTime, timeFormat);

									if (aTime.isBefore(bTime)) return -1;

									return 1;
								});
								dateIndex++;
							}

							return (
								<Day
									className={`${j === 0 || j === 6 ? 'col-sm-1' : 'col'} p-0`}
									key={j}
								>
									<div className="m-2" style={headerStyle}>
										{dateData.date}
									</div>
									{dateData.sessionData.map((sd, i) => (
										<div key={`sessionData-${i}`}>
											{sd.program && <AttendanceBlock data={sd} goto={goto} />}
										</div>
									))}
								</Day>
							);
						})}
					</div>
				))}
			</div>
		);
	}, [selectedMonth, programs, sessions, attendance]);

	return (
		<div className="container-fluid bg-white">
			{monthOptions.length === 0 || !selectedMonth ? (
				<div className="text-center w-100 font-30 py-3 pb-0 uppercase">
					No data to display
				</div>
			) : (
				<div
					className="row w-100 mx-2 justify-content-center pt-3 pb-0"
					style={{ fontSize: '34px', position: 'relative' }}
				>
					<LegendContainer>
						<ColorBlock type="Present" />
						<ColorBlock type="Absent" />
						<ColorBlock type="Excused" />
					</LegendContainer>
					<button
						className="custom-arrow left large hand"
						style={{
							marginTop: '22px',
							marginRight: '4px',
							borderColor:
								monthIndex > 0 ? 'var(--main-color)' : 'var(--main-light-grey)',
						}}
						onClick={prevMonth}
					/>
					<ButtonDropdown
						isOpen={monthDropdownOpen}
						toggle={toggleMonthDropdown}
						className="month-dropdown"
					>
						<DropdownToggle>{selectedMonth.label}</DropdownToggle>
						<DropdownMenu
							flip={false}
							style={{ maxHeight: 400, overflow: 'auto' }}
						>
							{monthOptions.map((o, i) => (
								<DropdownItem
									key={o.value}
									className={`month-item ${
										o.value === selectedMonth.value ? 'active' : ''
									}`}
									onClick={() => setMonthIndex(i)}
								>
									{o.label}
								</DropdownItem>
							))}
						</DropdownMenu>
					</ButtonDropdown>
					<button
						className="custom-arrow right large hand"
						style={{
							marginTop: '22px',
							marginLeft: '4px',
							borderColor:
								monthIndex < monthOptions.length - 1
									? 'var(--main-color)'
									: 'var(--main-light-grey)',
						}}
						onClick={nextMonth}
					/>
				</div>
			)}
			<div className="p-4">{renderMonth()}</div>
		</div>
	);
};

AttendanceRecord.propTypes = {
	data: PropTypes.array.isRequired,
	loadSession: PropTypes.func.isRequired,
};

export default AttendanceRecord;
