import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import PhoneNumberInput from 'atoms/PhoneNumberInput';
import TextInput from 'atoms/TextInput';
import { required } from 'utils/validations';

/**
 *
 * PatientEmergencyContacts
 *
 */

/* eslint-disable react/prefer-stateless-function */
class PatientEmergencyContacts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			emergencyContact: {
				emergencyContact: '',
				relationship: '',
				phone: null,
				email: '',
			},
			secondaryEmergencyContact: {
				emergencyContact: '',
				relationship: '',
				phone: null,
				email: '',
			},
			addOtherEmergencyContact: false,
		};
	}

	initContacts(patientData) {
		if(patientData.emergencyContact?.length > 0) {
			this.setState({
				emergencyContact: patientData.emergencyContact[0],
			});
		}
		if(patientData.contacts?.length > 1) {
			this.setState({
				addOtherEmergencyContact: true,
				secondaryEmergencyContact: patientData.emergencyContact[1],
			});
		}
		if(patientData.details.registrationType === 'child') {
			this.setState({
				addOtherEmergencyContact: true,
			});
		}
	}
	
	componentDidMount() {
		const { patientData } = this.props;
		this.initContacts(patientData);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.initContacts(nextProps.patientData);
	}

	addAnotherEmergencyContact = () => {
		if (this.state.addOtherEmergencyContact === false) {
			this.setState({ addOtherEmergencyContact: true });
		} else {
			this.setState({
				addOtherEmergencyContact: false,
				secondaryEmergencyContact: {
					emergencyContact: '',
					relationship: '',
					phone: null,
					email: '',
				},
			});
		}
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;
		if (name === 'emergencyContactName_1') {
			this.setState((prevState) => ({
				emergencyContact: {
					...prevState.emergencyContact,
					emergencyContact: value,
				},
			}));
		}
		if (name === 'emergencyContactName_2') {
			this.setState((prevState) => ({
				secondaryEmergencyContact: {
					...prevState.secondaryEmergencyContact,
					emergencyContact: value,
				},
			}));
		}
		if (name === 'emergencyContactRelationshipToPatient_1') {
			this.setState((prevState) => ({
				emergencyContact: {
					...prevState.emergencyContact,
					relationship: value,
				},
			}));
		}
		if (name === 'emergencyContactRelationshipToPatient_2') {
			this.setState((prevState) => ({
				secondaryEmergencyContact: {
					...prevState.secondaryEmergencyContact,
					relationship: value,
				},
			}));
		}
		if (name === 'emergencyContactEmail_1') {
		  this.setState(prevState => ({
		    emergencyContact: {
		      ...prevState.emergencyContact,
		      email: value,
		    },
		  }));
		}
		if (name === 'emergencyContactEmail_2') {
		  this.setState(prevState => ({
		    secondaryEmergencyContact: {
		      ...prevState.secondaryEmergencyContact,
		      email: value,
		    },
		  }));
		}
		// if (name === 'emergencyContactPhone_1') {
		//   this.setState(prevState => ({
		//     emergencyContact: {
		//       ...prevState.emergencyContact,
		//       workPhone: value,
		//     },
		//   }));
		// }
		// if (name === 'emergencyContactPhone_2') {
		//   this.setState(prevState => ({
		//     secondaryEmergencyContact: {
		//       ...prevState.secondaryEmergencyContact,
		//       workPhone: value,
		//     },
		//   }));
		// }
		// else {
		//   this.setState({ [name]: value });
		// }
	};

	handleNumberChange = (id, values) => {
		if (id === 'emergencyContactPhone_1') {
			this.setState((prevState) => ({
				emergencyContact: {
					...prevState.emergencyContact,
					phone: values.value,
				},
			}));
		}
		if (id === 'emergencyContactPhone_2') {
			this.setState((prevState) => ({
				secondaryEmergencyContact: {
					...prevState.secondaryEmergencyContact,
					phone: values.value,
				},
			}));
		}
	};

	nextStep = () => {
		const { step, auth } = this.props;

		const {
			emergencyContact,
			addOtherEmergencyContact,
			secondaryEmergencyContact,
		} = this.state;
		const data = {};
		data.emergencyContact = [];
		data.emergencyContact.push(emergencyContact);
		if (addOtherEmergencyContact) {
			data.addOtherEmergencyContact = true;
			data.emergencyContact.push(secondaryEmergencyContact);
		}
		const keys = Object.getOwnPropertyNames(data);
		data.id = auth.id;
		data.q = keys.join();
		step(data);
	};

	prevStep = () => {
		const { previousStep } = this.props;
		previousStep();
	};

	getEmergencyContact = (index) => {
		const isChildRegistration = this.props.patientData.details.registrationType === 'child';
		const emergencyContactName = `emergencyContactName_${index}`;
		const emergencyContactRelationshipToPatient = `emergencyContactRelationshipToPatient_${index}`;
		const emergencyContactPhone = `emergencyContactPhone_${index}`;
		const emergencyContactEmail = `emergencyContactEmail_${index}`;
		let emergencyContactLabel =
			isChildRegistration
				? `GUARDIAN CONTACT (${index})`
				: `EMERGENCY CONTACT (${index})`;
		let emergencyContactRelation = 'RELATIONSHIP TO PATIENT';
		let emergencyContactPhoneLabel =
			isChildRegistration
				? "GUARDIAN CONTACT'S PHONE"
				: "EMERGENCY CONTACT'S PHONE";
		let emergencyContactEmailLabel = 
			isChildRegistration
				? "GUARDIAN CONTACT'S EMAIL"
				: "EMERGENCY CONTACT'S EMAIL";
		// show that details for first emergency contact are required
		if (index === 1) {
			emergencyContactLabel += '*';
			emergencyContactPhoneLabel += '*';
			emergencyContactRelation += '*';
			emergencyContactEmailLabel += '*';
		}
		let contactRelation =
			index === 1
				? this.state.emergencyContact.relationship
				: this.state.secondaryEmergencyContact.relationship;

		return (
			<div>
				<div className="form-row">
					<div className="col-md-6 form-group">
						<TextInput
							label={emergencyContactLabel}
							id={emergencyContactName}
							value={
								index === 1
									? this.state.emergencyContact.emergencyContact
									: this.state.secondaryEmergencyContact.emergencyContact
							}
							placeholder={index === 1 ? 'Jessica Doe' : 'John Doe'}
							handleInput={this.handleChange}
							validations={index === 1 ? [required] : []}
						/>
					</div>
					<div className="col-md-6 form-group">
						<TextInput
							label={emergencyContactRelation}
							id={emergencyContactRelationshipToPatient}
							value={contactRelation}
							placeholder={index === 1 ? 'Mother' : 'Father'}
							handleInput={this.handleChange}
							validations={index === 1 ? [required] : []}
						/>
					</div>
				</div>

				<div className="form-row">
					<div className="col-md-6 form-group">
						<PhoneNumberInput
							label={emergencyContactPhoneLabel}
							id={emergencyContactPhone}
							value={
								index === 1
									? this.state.emergencyContact.phone
									: this.state.secondaryEmergencyContact.phone
							}
							placeholder="Enter 10 Digit Main Phone"
							handleInput={this.handleNumberChange}
							isRequired
						/>
					</div>
					<div className="col-md-6 form-group">
						<TextInput
							label={emergencyContactEmailLabel}
							id={emergencyContactEmail}
							value={
								index === 1
									? this.state.emergencyContact.email
									: this.state.secondaryEmergencyContact.email
							}
							placeholder="Enter Email Address"
							handleInput={this.handleChange}
							validations={index === 1 ? [required] : []}
						/>
					</div>
				</div>
			</div>
		);
	};

	render() {
		const {
			addOtherEmergencyContact,
			emergencyContact: { emergencyContact, relationship, phone, email },
		} = this.state;
		const isChildRegistration = this.props.patientData.details.registrationType === 'child';

		const canProceed = (phone?.length === 10 && emergencyContact && relationship && email);

		return (
			<div>
				<Form>
					{isChildRegistration && 
						<label className="form-font mb-3">
							PLEASE ADD DETAILS FOR TWO GUARDIANS IF APPLICABLE:
						</label>
					}
					{this.getEmergencyContact(1)}

					{addOtherEmergencyContact && this.getEmergencyContact(2)}
					{addOtherEmergencyContact && (
						<button
							type="button"
							className="btn btn-white remove-button border btn-lg btn-block font-15 mt-3 font-weight-bold"
							onClick={this.addAnotherEmergencyContact}
						>
							REMOVE ADDITIONAL EMERGENCY CONTACT
						</button>
					)}
					{!addOtherEmergencyContact && (
						<button
							type="button"
							className="btn btn-white link-color border btn-lg btn-block font-15 mt-3 font-weight-bold"
							onClick={this.addAnotherEmergencyContact}
						>
							+ ADD ANOTHER EMERGENCY CONTACT
						</button>
					)}

					<div className="d-flex mt-4">
						<div className="col-6 pl-0">
							<button
								type="button"
								className="btn btn-white link-color border btn-lg btn-block font-15 font-weight-bold d-flex justify-content-start"
								onClick={this.prevStep}
							>
								<div className="col-4 d-flex justify-content-start align-self-center">
									<img className="fas fa-arrow-left" alt="arrow-left" />
								</div>
								<div className="col-4 d-flex justify-content-center">
									<span>PREV</span>
								</div>
							</button>
						</div>
						<div className="col-6 p-0 d-flex">
							<button
								disabled={!canProceed}
								type="button"
								className="btn btn-primary btn-color btn-lg btn-block font-15 font-weight-bold d-flex justify-content-end"
								onClick={this.nextStep}
							>
								<div className="col-4 d-flex justify-content-center">
									<span>NEXT</span>
								</div>
								<div className="col-4 d-flex justify-content-end align-self-center">
									<img className="fas fa-arrow-right" alt="arrow-right" />
								</div>
							</button>
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

PatientEmergencyContacts.propTypes = {
	patientData: PropTypes.object.isRequired,
	step: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	previousStep: PropTypes.func.isRequired,
};

export default PatientEmergencyContacts;
